@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower|Lobster+Two|Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
body, html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
    background-color:#090909;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.App-main {
    min-height: 95vh; 
  }

.App-footer {
    min-height: 5vh; 
  }  

.particle{
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.home-left{
    height: 100%;
 }

.home-left-main{
    height: 100%;
    text-align: center;
}

.home-left-main-col{
    margin: auto;    
    display: block;
}

.first-line{
    font-size: 4.5rem;
    font-family: 'Sulphur Point', sans-serif;
}

.second_line{
    font-size: 3rem;
    font-family: 'Julius Sans One', sans-serif;
}

.typical{
    font-size: 1.6rem;
    font-family: 'Sulphur Point', sans-serif;
}

.home-left-aboutme-main{
    position: relative;
    z-index: -1;
}

.home-left-aboutme{
   color: aliceblue;
}

.home-left-aboutme:hover{
    color: black;
 }

.home-left-aboutmme-btn:hover{
     background: #666666;
 }


  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      height: 65vh;
      margin: auto;    
      display: block;
      border-radius: 2%;
  }

  .home-right-footer{
     width: 100%;
  }

.social-icons{
    text-align: center;
  }
 
  .fb{
    margin: 2%;
    color: white;
  }

  .fb:hover{
    color: #386ddd;
  }

  .git{
    margin: 2%;
    color: white;
  }

  .git:hover{
    color: #16fa01;
  }

  .twitter{
    margin: 2%;
    color: white;
  }

  .twitter:hover{
    color: #00acee;
  }

  .insta{
    margin: 2%;
    color: white;
  }

  .insta:hover{
    color: #c1558b;
  }

  .linkedin{
    margin: 2%;
    color: white;
  }
  
  .linkedin:hover{
    color: #0e76a8;
  }
.footer{
    height: 100%;
    text-align: center;
    font-size: medium;
    color: rgb(202, 200, 200);
}

:root {
  --overlay-color: rgba(66, 66, 66, 0.85);
  }

.menu-wrap {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1;
   font-family: 'Poppins', sans-serif;
 }
 
 .menu-wrap .toggler {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   cursor: pointer;
   width: 50px;
   height: 50px;
   opacity: 0;
 }
 
 .menu-wrap .hamburger {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;
   width: 80px;
   height: 80px;
   padding: 1rem;
   background: var(--primary-color);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
 }
 
 /* Hamburger Line */
 .menu-wrap .hamburger > div {
   position: relative;
   -webkit-flex: none;
           flex: none;
   width: 50%;
   height: 2px;
   background: rgb(240, 177, 118);
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
   transition: all 0.4s ease;
 }
 
 /* Hamburger Lines - Top & Bottom */
 .menu-wrap .hamburger > div::before,
 .menu-wrap .hamburger > div::after {
   content: '';
   position: absolute;
   z-index: 1;
   top: -10px;
   width: 100%;
   height: 2px;
   background: inherit;
 }
 
 /* Moves Line Down */
 .menu-wrap .hamburger > div::after {
   top: 10px;
 }
 
 /* Toggler Animation */
 .menu-wrap .toggler:checked + .hamburger > div {
   -webkit-transform: rotate(135deg);
           transform: rotate(135deg);
 }
 
 /* Turns Lines Into X */
 .menu-wrap .toggler:checked + .hamburger > div:before,
 .menu-wrap .toggler:checked + .hamburger > div:after {
   top: 0;
   -webkit-transform: rotate(90deg);
           transform: rotate(90deg);
 }
 
 /* Rotate On Hover When Checked */
 .menu-wrap .toggler:checked:hover + .hamburger > div {
   -webkit-transform: rotate(225deg);
           transform: rotate(225deg);
 }
 
 /* Show Menu */
 .menu-wrap .toggler:checked ~ .menu {
   visibility: visible;
 }
 
 .menu-wrap .toggler:checked ~ .menu > div {
   -webkit-transform: scale(1);
           transform: scale(1);
   transition-duration: var(--menu-speed);
 }
 
 .menu-wrap .toggler:checked ~ .menu > div > div {
   opacity: 1;
   transition:  opacity 0.01s ease 0.01s;
 }
 
 .menu-wrap .menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
   overflow: hidden;
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
 }
 
 .menu-wrap .menu > div {
   background: rgba(66, 66, 66, 0.85);
   background: var(--overlay-color);
   width: 200vw;
   height: 200vw;
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
 }
 
 .menu-wrap .menu > div > div {
   text-align: center;
   max-width: 100vw;
   max-height: 100vh;
   opacity: 0;
 }
 
 ul {
  position: relative;
}

ul li{
  list-style: none;
  text-align: center;
}

ul li a{
  color: rgb(230, 104, 95);
  text-decoration: none;
  font-size: 1.7em;
  padding: 5px 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

ul:hover li a{
  color:  rgb(214, 94, 90);
}

ul li:hover a{
  font-size: 3em;
  color: rgb(221, 70, 24);
  background: rgb(235, 87, 18);
}

ul li a:before{
  content: '';
  position: absolute;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 500px;
}

.About-header {
  background-color:#090909;
 }

.About-main {
  min-height: 95vh;
}

.d1{
  height: 100%;
  width: 100%;
 }

.d2{
  height: 100%;
  width: 100%;
 }

.d3{
  height: 100%;
  width: 100%;
 }

.About-footer {
  min-height: 5vh;
}


.clc_main{
    color: white;
    height: 100%;
    width: 100%;
    padding: 2%;
    padding-top: 30%;
}

.clc_container{
  padding: 2%;
}

.clc_header{
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}

.clc_sub_header{
  font-family: 'Indie Flower', cursive;
}

.clc_paragraph{
  font-family: 'Roboto Mono', monospace;
}

.AboutBtnContainer{
  width: 100%;
}

.left{
  width: 50%;
  float: left;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 900;
  color: aliceblue;
}

.right{
  width: 50%;
  float: right;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 900;
}

.right_icon{
  font-size: 100px;
}

.right_icon:hover{
  color: #F1502F;
  cursor: pointer;
}

.left_icon{
font-size: 100px;
}

.left_icon:hover{
  color: #ffbb48;
  cursor: pointer;
}

.about_modal{
  background-color: rgb(9, 9, 9);
}

.custom-modal{
  width: 100VW;
  height: 100VH;
}

.modal-exit-btn:hover{
  background-color:#666666;
  color: seashell;
  cursor: pointer;
}

.modalHeader{
  background-color: #090909;
  color: aliceblue !important;
}

.close{
  color: aliceblue;
}
.project_main{
    height: 100%;
    width: 100%;
    color: white;
    background-color: rgb(9,9,9);
}

.project_header{
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Sulphur Point', sans-serif;
}

.modal_close_icon_container{
    text-align: right;
    font-size: 20px;
}
.project_card_right{
    color: white;
}

.project_card_left_img{
    height: 45%;
    width: 45%;
    background: white;
}

.project_img_container{
    text-align: center;
    margin: 0 auto;
    display: table;
    
}

.project_card_main{
    text-align: center;
    margin: 0 auto;
    display: table;
}

.pro_card{
    background-color: transparent;
    border-width: 0.01px;
    border-color: honeydew;
    margin: 1%;
    text-align: center;
}

.repoName{
    font-size: 1.5rem;
    font-family: 'Sulphur Point', sans-serif;
    font-weight: 600;
}

.repoInfo{
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 600;
}
    
.cv_img_mouse{
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  width: 100%;
  max-height: 95vh;
}

.Contact-header {
  background-color:#090909;
  color: white;
}

.Hamburger-menu{
  min-height: 7vh; 
}

.Contact-main {
  min-height: 88vh; 
 }

.Contact-left{
  margin: auto;    
  display: block;
}

.Contact-right{
  margin: auto;    
  display: block;
}

.Contact-footer {
  min-height: 5vh; 
}  


.Contact-text{
  text-align: center;
 }

.p-heading1{
  font-size: 4rem;
  font-family: 'Sulphur Point', sans-serif;
}

.p-heading2{
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Roboto Mono', monospace;
}

.contact-left-footer{
  width: 100%;
  font-size: 1.7rem;
}

.Contact-form{
    text-align: center;
    padding-bottom: 8%;
  }

.contact-form-header{
    font-size: 2.5rem;
    padding: 1%;
    font-family: 'Raleway', sans-serif;
   }

.contact_input{
   padding-left: 10%;
   padding-right: 10%;
  }

.contact_input_text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.5rem;
    border-radius: 5%;
    text-align: center;
    background: transparent;
    color: white;
    min-height: 6.5vh;
  }
   
.contact_success_modal_body{
    text-align: center;
    background: #090909;
    color: aliceblue;
   }
   
.contact_success_modal_img{
     height: 15vh;
     padding-bottom: 3%;
   }
 
.contact-email-text-btn:hover{
      background: #666666;
      color: aliceblue;
  }
 
.Loading-header {
    background-color: #000000;
    background-image: url(/static/media/foad_logo.0a04cd22.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

.About-header {
  background-color:#090909;
  /* color: #1b1b1b; */
 }

.About-main {
  min-height: 95vh;
}

.d1{
  height: 100%;
  width: 100%;
 }

.d2{
  height: 100%;
  width: 100%;
 }

.d3{
  height: 100%;
  width: 100%;
 }

.About-footer {
  min-height: 5vh;
}

.main_row{
    font-family: 'Sulphur Point', sans-serif;
}
.card_col{
    padding-left: 4%;
    padding-top: 4%;
}

.card_main{
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-color: white;
    text-align: right;
}

.card_main:hover{
   transition: all 0.2s ease-out;
   top: -4px;
   background: rgba(255, 255, 255, 0.15);
   box-shadow: 0px 4px 35px rgb(255, 255, 255);
}

.blockquote-footer{
    color: white;
}

.icon{
    color: white;
    cursor: pointer;
}

.icon:hover{
    color: rgb(255, 196, 102);
 }
